//project JS
var exhibit_carousel = $('.exhibit-carousel');

// document.fonts.ready.then(function (set) {
//     // Log that the fonts are loaded
//     console.log(set);
//     var t;
//     for (var t of set.entries()) {
//         console.log(t);
//     }
// });

$(document).ready(function () {
    console.log('project js ready');
    modular_posts_block_init();
    exhibit_carousel = $('.exhibit-carousel');



    if (exhibit_carousel.length) {
        exhibit_carousel.slick({

            infinite: true,
            dots: true,
            arrows: false,
            adaptiveHeight: true,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            cssEase: 'linear'
        });
    }


});

jQuery(window).on('scroll resize load', _.throttle(function () {
    exhibit_carousel_pause_when_not_in_view();
}, 100));


function exhibit_carousel_pause_when_not_in_view(){
    if(!exhibit_carousel.length){
        return;
    }
    var nav_height = $('#masthead').outerHeight();
    var adminbar_height = $('#wpadminbar').outerHeight();
    console.log(nav_height);
    console.log(adminbar_height);
    var is_carousel_visible = checkVisible(exhibit_carousel[0],100+nav_height+adminbar_height);
    console.log(is_carousel_visible);
    if (is_carousel_visible) {
        exhibit_carousel.slick('slickPlay');
    }else{
        exhibit_carousel.slick('slickPause');
    }
}

/*
@auth Tokimon
@link https://stackoverflow.com/questions/5353934/check-if-element-is-visible-on-screen
*/
function checkVisibleSimple(elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}
function checkVisible(elm, threshold, mode) {
    threshold = threshold || 0;
    mode = mode || 'visible';
  
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;
  
    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
  }