function modular_posts_block_init() {
    console.log('modular_posts_block_init');
    if (!$('.modular-posts-block').length) {
        return;
    }
    console.log($('.modular-posts-block'));
    $('.modular-posts-block').each(function () {
        var $modular_posts_block = $(this);

        $(this).find('.category-title').css('display','inline');
        $(this).find('.category-link-wrapper').css('display','inline');

        $modular_posts_block.find('article').each(function () {
            var $article = $(this);
            $article.find('.post-date').prependTo($article.find('.info-wrapper'));


            if ($article.hasClass('landscape') && $article.hasClass('only-post') ) {
                $article.closest('.modular-posts-block').find('.category-title').prependTo($article.find('.info-wrapper'));
                $article.closest('.modular-posts-block').find('.category-link-wrapper').appendTo($article.find('.info-wrapper'));
            }

            
        });


    });
}