
$ = jQuery;
var _$ = jQuery;
var windowWidth;

var stickyElements = jQuery('.sticky');
var slickElements = jQuery('.slick-carousel');

var is_chrome = navigator.userAgent.indexOf('Chrome') > -1,
    is_explorer = getInternetExplorerVersion() > -1,
    is_firefox = navigator.userAgent.indexOf('Firefox') > -1,
    is_safari = navigator.userAgent.indexOf("Safari") > -1;



/* Window Width */
function setWindowWidth() {
    windowWidth = window.innerWidth;
}
jQuery(window).resize(setWindowWidth);

//document ready only here
$(document).ready(function () {
    console.log('ads-assets-js ready');
    jQuery('html').attr('data-browser', navigator.userAgent);

    is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
    is_explorer = getInternetExplorerVersion() > -1;
    is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
    is_safari = navigator.userAgent.indexOf("Safari") > -1;

    setWindowWidth();

    if (stickyElements.length) {
        Stickyfill.add(stickyElements);
    }

    if (slickElements.length) {
        slickElements.slick({

            // normal options...
            infinite: false,

            // the magic
            responsive: [{

                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true
                }

            }, {

                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }

            }, {

                breakpoint: 300,
                settings: "unslick" // destroys slick

            }]
        });
    }

});
//other document... stuff here


