//Navigation functions
var _$ = jQuery;
var previousAnchor;
var prevScrollpos = window.pageYOffset;
var currentScrollPos = window.pageYOffset;

// function toggleNavByClick(trigger){
//     $(trigger).click(function(){
//         var target = $(trigger).attr('data-target');
//         $(target).toggleClass('show');
//     });
// }

function animateHeader() {
   
    var adminbar = $('#wpadminbar');
    var adminbarHeight = adminbar.outerHeight();

    var navbar_default_top = !!adminbar.length ? adminbar.outerHeight() + 'px' : '';


    var navbar = $('#masthead');
    var navbarHeight = navbar.outerHeight();

    if ($('body').hasClass('nav-open')) {//prevent header animation if is mobile and nav is open

        navbar.css('top', navbar_default_top);
        return;
    }
    
    if (prevScrollpos > currentScrollPos) {
        //show nav
        navbar.css('top', navbar_default_top);
    } else if(prevScrollpos + 100 < currentScrollPos) {

        //hide nav after scrolling 100 pixels
        var toppx = (navbarHeight + adminbarHeight) * (-1);
        navbar.css('top', toppx);

    }

    prevScrollpos = currentScrollPos;
}



/* Add scroll class to body if not on top */
jQuery(window).on('scroll resize load', _.throttle(function () {
    if (jQuery(window).scrollTop() > 40) jQuery(document.body).addClass('scroll');
    else jQuery(document.body).removeClass('scroll');
    currentScrollPos = window.pageYOffset;
    animateHeader();
}, 100));

 




/*
* Scroll to position with jQuery
* @author Jake Bresnehan
* $link https://web-design-weekly.com/snippets/scroll-to-position-with-jquery/
* usecase: jQuery(target-element).scrollView(offset,speed);
*/
jQuery.fn.scrollView = function (offset, speed) {

    offset = offset || 0;
    speed = speed || 10;

    return this.each(function () {
        jQuery('#masthead').finish();
        jQuery('html, body').animate({
            scrollTop: $(this).offset().top + offset
        }, speed, 'swing', function () {
            jQuery('#masthead').finish();
        });
    });

}


//Anchor Scroll Offset
var shiftWindow = function () {
    // console.log('window shift');
    var target = _$(location.hash);
    if (!target.length || target.closest('iframe').length) {
        return;
    }

    var navbar = _$('#masthead');
    var adminbar = _$('#wpadminbar');
    var offset = target.offset().top - 1 * (navbar.outerHeight(true) + adminbar.outerHeight(true));

    scrollTo(0, offset);

    previousAnchor = target;
};


function getInternetExplorerVersion() {
    var rv = -1;
    if (navigator.appName == 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);
    }
    else if (navigator.appName == 'Netscape') {
        var ua = navigator.userAgent;
        var re = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);
    }
    return rv;
}

function navigation_title_link_init() {
    /** 
     * Disable WP Nav links that have no-hover class, these links act as headings for other links
     */
    _$('#masthead .nav-link.no-hover').attr('tabindex', '-1');
    _$('#masthead .nav-link.no-hover').on('click', function (e) {
        e.preventDefault();
    });
}

function adminbar_related_adjustments() {

    if (jQuery('#wpadminbar').length > 0) {
        jQuery('body > #page').css('padding-top', jQuery('#wpadminbar').height());
    }
}

// DOCUMENT READY
jQuery(function () {
    console.log('ads-assets-nav ready');

    if ( is_safari && is_chrome ) {
        jQuery(document.body).addClass('isSafari');
    }
    else {
        var elements = jQuery('#masthead');
        if (elements.length) {
            Stickyfill.add(elements);
        }
    }

    _$('#site-nav-toggle').on('click', function () {

        if (_$('#primary-menu-wrapper').hasClass('collapsing')) {
            return;
        }
        
    });

    $('#primary-menu-wrapper').on('show.bs.collapse', function () {
        _$('html').addClass('noscroll');
        _$('body').addClass('nav-open');
    });

    $('#primary-menu-wrapper').on('hide.bs.collapse', function () {
        // console.log('closing nav');
        _$('#page > div').first().css('height', 'auto');
        _$('#page > div').first().css('min-height', '70px');
        _$('#page > div').first().css('transition', '2s height');
    });

    $('#primary-menu-wrapper').on('hidden.bs.collapse', function () {
        // console.log('closed nav');
        _$('#page > div').first().css('height', 'auto');
        _$('html').removeClass('noscroll');
        _$('body').removeClass('nav-open');
    });

    navigation_title_link_init();

    //Anchor Scroll events
    if (location.hash) shiftWindow();
    window.addEventListener("hashchange", shiftWindow);
    jQuery('#content').on('click', 'a[href*="#"]', shiftWindow);


    adminbar_related_adjustments();

});//DOM ready


function no_pagescroll_when_mobilenav_is_open() {
    if (windowWidth >= 1200) {
        jQuery('html').removeClass('noscroll');
    } else {
        if (!jQuery('body').hasClass('nav-open')) {
            jQuery('html').removeClass('noscroll');
        }
        else if (jQuery('body').hasClass('nav-open')) {
            jQuery('html').addClass('noscroll');
        }
    }
}

jQuery(window).on('resize', _.throttle(function () {

    no_pagescroll_when_mobilenav_is_open();

    adminbar_related_adjustments();

}, 400));


